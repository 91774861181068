<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <columns>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="list-check" class="fa-2x has-text-grey"/>
            <p>
              # Checklists Performed
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_performed | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="bolt" class="fa-2x has-text-grey"/>
            <p>
              Total # of Failures
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_failures | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="thumbs-up" type="far" class="fa-2x has-text-grey"/>
            <p>
              Total # Passed
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_passed | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="stopwatch" class="fa-2x has-text-grey"/>
            <p>
              Average Runtime
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.average_run_time }}</p>
            </div>
          </div>
        </column>
      </columns>

      <columns>
        <column>
          <div class="box">
            <checklists-line-chart 
              :summary="overview.checklist_graph"
            />
          </div>
        </column>
      </columns>
    </column>
    <column class="is-3">
      <p class="is-size-3">Overview</p>
      <p>Last updated {{ checklist.updated_at | asCalendar }}</p>
      <p>Pass vs Failure Ratio</p>
      <fail-vs-pass-donut />
    </column>
  </columns>  
</loader>  
</template>
<script>
import { mapGetters } from 'vuex'
import ChecklistsLineChart from './partials/ChecklistsLineChart.vue'
import FailVsPassDonut from './partials/FailVsPassDonut.vue'
export default {
  components: { ChecklistsLineChart, FailVsPassDonut },
 
  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('checklist/loadOverview', this.$route.params.checklistTemplate)
    this.loading = false
  },

  computed: {
    ...mapGetters('checklist', [
      'checklist',
      'overview'
    ])
  }

}
</script>