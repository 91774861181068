<template>
  <div>
    <div id="customer-equipment-health">
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    chartOptions: {
      chart: {
        type: "donut",
      },
      colors: ["#E91E63", "#1de98a"],
      labels: ["Failed", "Passed"],
      legend: {
        show: false,
      },
      stroke: {
        colors: ["#212127"],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  }),

  computed: {
    ...mapGetters("checklist", ["overview"]),
    series() {
      return [this.overview.total_failures, this.overview.total_passed];
    },
  },
};
</script>